import { useContext, useState } from 'react';
import { CartContext } from '../CartContext';

import BasketModal from './BasketModal';

export default function ProductCard(props) {

    const [showBasketModal, setShowBasketModal] = useState(false);
    const products = props.productData
    const cart = useContext(CartContext)

    let productQuantity = 0
    products.forEach(product => {
        productQuantity += cart.getProductQuantity(product.id)
    })

    const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);

    return (
        <>
            {productQuantity > 0 ?
                <>
                    <div className="p-0">
                        <button className="bg-transparent border-4 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full btn btn-outline  text-white mt-10" onClick={
                            () => {
                                cart.toggleBasket()
                            }
                        }>
                            Checkout ...
                        </button>
                    </div>
                </>
                :
                <></>
            }
            <BasketModal
                setShowBasketModal={setShowBasketModal}
                showBasketModal={showBasketModal}
                cart={cart}
                productsCount={productsCount}
            />
        </>
    )
}
