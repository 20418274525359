import { useState, useRef } from 'react';
import CartProduct from './CartProduct';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";
import { lookupRecord } from '../data/content';

export default function BasketModal({ setShowBasketModal, showBasketModal, cart, productsCount }) {

  const termsContent = lookupRecord('help.terms-&-conditions', '')
  const privacyContent = lookupRecord('help.privacy-notice', '')

  const modalToggleRef = useRef(null);

  const [terms, setTerms] = useState(true);
  const [buyDisabled, setBuyDisabled] = useState('disabled');
  const [modal, setModal] = useState(false)
  const [loadingClass, setLoadingClass] = useState('');
  const functions = getFunctions(getApp());

  function buyNow() {
    const checkoutRequest = httpsCallable(functions, 'stripePayment');

    setLoadingClass('loading');

    checkoutRequest({ text: 'call to make stripe payment ...', items: cart.items })
      .then((r) => {
        if (r.data) {
          if (r.data.result === 'ok') {
          }
          console.log("success : ", r.data);
          if (r.data.url) {
            window.location.assign(r.data.url); // Forwarding user to Stripe
          }
        }
      })
      .catch((error) => {
        console.log("failed : ", error);
      });

  }

  const toggleTerms = () => {
    setTerms(!terms);
    if (terms) {
      setBuyDisabled('');
    } else {
      setBuyDisabled('disabled');
    }
    console.log('_____----> ', terms);
  }

  const toggleModal = () => {
    setModal(!modal)
    modalToggleRef.current.click();

  }

  return (
    <div className='text-center'>
      <input id="my-modal" type="checkbox" className="modal-toggle" checked={cart.shownBasket} onChange={e => { }} />
      <div className="modal">
        <div className="modal-box">
          {productsCount > 0 ?
            <>
              <p className='text-black'>Items in your cart:</p>
              {cart.items.map((currentProduct, idx) => (
                <CartProduct key={idx} id={currentProduct.id} quantity={currentProduct.quantity}></CartProduct>
              ))}
              <h1>Total: {cart.getTotalCost().toFixed(2)}</h1>
            </>
            :
            <>
              <h1>There are no items in your cart</h1>
              <div className="p-5 text-black">
                <button className="btn-wide bg-transparent border-4 hover:bg-blue-700 hover:border-opacity-10 text-slate-800 font-bold py-2 px-4 rounded-full btn btn-outline mt-10" onClick={() => cart.toggleBasket(false)}>
                  Back
                </button>
              </div>
            </>
          }
          <div className="">
            {productsCount > 0 && (
              <div className="p-5 ">
                <div className='flex flex-col border border-0 border-green-500'>

                  <button onClick={() => toggleModal()} className="mx-auto btn-wide bg-transparent border-4 hover:bg-blue-700 hover:border-opacity-10 text-slate-800 font-bold py-2 px-4 rounded-full btn btn-outline mt-10" >
                    View Terms and Conditions
                  </button>




                  <div className="mt-8 form-control">
                    <label className=" w-2/3 border border-0 border-red-600 label cursor-pointer mx-auto">
                      <span className="label-text text-xl text-red-700 font-bold">Agree to Terms and Conditions </span>
                      <input onChange={() => toggleTerms()} type="checkbox" className="checkbox checkbox-primary" />
                    </label>
                  </div>
                </div>

                <button
                  disabled={buyDisabled}
                  className={`btn ${loadingClass} btn-wide bg-transparent border-4 hover:bg-blue-700 hover:border-opacity-10 text-slate-800 font-bold py-2 px-4 rounded-full btn-outline mt-10`}
                  onClick={() => buyNow()}
                >Buy now</button>

              </div>
            )}
          </div>
        </div>
      </div>




      { /* TERMS MODAL */}

      {/* The button to open modal */}

      <input type="checkbox" id="terms-modal" className="modal-toggle" ref={modalToggleRef} />


      <input type="checkbox" id="terms-modal" className="modal-toggle" ref={modalToggleRef} />




      <div className="modal">


        <div className="modal-box w-11/12 max-w-5xl" style={{ height: '80vh', overflowY: 'auto' }}>


          <div className="prose lg:prose-xl mx-auto">



            {termsContent.content && (
              <div className='p-10 text-3xl'>{termsContent.content[0]}</div>
            )}



            {termsContent.content && termsContent.content.map((item, index) => {
              if (index > 0) {
                return (
                  <div key={index} className='p-3'>{item}</div>
                )
              }
              return null
            })}


            {privacyContent.content && (
              <div className='p-10 text-3xl'>{privacyContent.content[0]}</div>
            )}


            {privacyContent.content && privacyContent.content.map((item, index) => {
              if (index > 0) {
                return (
                  <div key={index} className='p-3'>{item}</div>
                )
              }
              return null
            })}
          </div>

          <button onClick={() => {
            toggleModal()
          }} className='btn-wide bg-transparent border-4 hover:bg-blue-700 hover:border-opacity-10 text-slate-800 font-bold py-2 px-4 rounded-full btn btn-outline mt-10'>Close</button>

        </div>
      </div >
    </div >
  )
}






